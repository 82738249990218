import request from '@/plugins/axios'


// 话题列表
export const apiCommunityTopicLists = (params: any) => request.get('/community.community_topic/lists', { params })

// 添加话题
export const apiCommunityTopicAdd = (params: any) => request.post('/community.community_topic/add', params)

// 编辑话题
export const apiCommunityTopicEdit = (params: any) => request.post('/community.community_topic/edit', params)

// 删除话题
export const apiCommunityTopicDelete = (params: any) => request.post('/community.community_topic/delete', params)

// 话题详情
export const apiCommunityTopicDetail = (params: any) => request.get('/community.community_topic/detail', { params })

export const apiCommunityTopicSwitchStatus = (params: any) => request.post('/community.community_topic/status', params)